<template>
  <div id="app">
    <!-- Navbar is included here -->
    <Navbar />

    <!-- Main content section -->
    <div class="content">
      <!-- This is where the content of each route will be rendered -->
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
};
</script>

<style scoped>
/* Padding for content to avoid being hidden behind the fixed navbar */
.content {
  padding-top: 60px; /* Adjust this value based on the height of your navbar */
}

/* Media query to adjust padding for larger screens */
@media (min-width: 768px) {
  .content {
    padding-top: 65px; /* Adjusted padding for medium and larger screens */
  }
}
</style>
