<template>
  <!-- FIRST SECTION OF THE HOME PAGE -->
  <div class="md:bg-[url('@/assets/mobil2.jpg')] bg-[url('@/assets/Home1.jpg')] bg-cover overflow-hidden h-screen">
    <div class="text-white h-[100dvh]">
      <div class="bg-gray-800 bg-opacity-60 h-full flex justify-center items-center flex-col">
        <p class="md:text-5xl sm:text-5xl lg:text-6xl text-2xl font-extrabold text-white pt-28">ZION TECH UGANDA</p>
        <p class="md:text-4xl pt-2 font-light text-lg text-white">Grow Your Business Online</p>
      </div>
    </div>
  </div>

  <!-- NEW SECTION: OUR EXPERTISE -->
  <section class="bg-white py-16">
    <div class="max-w-6xl mx-auto text-center">
      <h2 class="text-3xl md:text-4xl font-extrabold text-green-600">Why Choose Zion Tech Uganda?</h2>
      <p class="text-lg md:text-xl text-gray-600 mt-4">We provide cutting-edge services to help businesses thrive in the digital landscape.</p>

      <!-- Key Features Section -->
      <div class="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
        <Transition name="fade" mode="out-in">
          <div key="web-development" class="hover:scale-105 transition transform">
            <div class="p-6 bg-red-100 rounded-lg shadow-md hover:shadow-lg">
              <h3 class="text-2xl font-bold text-red-600">Web Development</h3>
              <p class="mt-2 text-gray-600">Building responsive and user-centric websites that drive engagement.</p>
            </div>
          </div>
        </Transition>
        
        <Transition name="fade" mode="out-in">
          <div key="mobile-development" class="hover:scale-105 transition transform">
            <div class="p-6 bg-green-100 rounded-lg shadow-md hover:shadow-lg">
              <h3 class="text-2xl font-bold text-green-600">Mobile Development</h3>
              <p class="mt-2 text-gray-600">Creating seamless mobile applications for enhanced user experiences.</p>
            </div>
          </div>
        </Transition>

        <Transition name="fade" mode="out-in">
          <div key="digital-marketing" class="hover:scale-105 transition transform">
            <div class="p-6 bg-yellow-100 rounded-lg shadow-md hover:shadow-lg">
              <h3 class="text-2xl font-bold text-yellow-600">Digital Marketing</h3>
              <p class="mt-2 text-gray-600">Implementing effective strategies to elevate your brand's online presence.</p>
            </div>
          </div>
        </Transition>
      </div>

      <!-- Call to Action -->
      <div class="mt-12">
        <p class="text-lg md:text-xl font-medium text-gray-700">We offer many more services to elevate your business.</p>
        <router-link to="/service" class="mt-4 inline-block bg-green-600 text-white py-2 px-6 rounded-lg font-semibold transition duration-300 hover:bg-green-700">
          Explore Our Services
        </router-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WelcomeComponent',
});
</script>

<style scoped>
/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Hover effect */
.hover\:scale-105:hover {
  transform: scale(1.05);
}
</style>
